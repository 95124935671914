import { Link } from "gatsby"
import React from "react"

import './header.scss'
import { useState } from "react"

const Header = () => {
  const [menuOpen, setmenuOpen] = useState();

  return (
    <header className="header">
      <Link className="logo" to="/">
        Stuart Hazley
        <span>Typeface Designer</span>
      </Link>
      <nav className={`navbar ${menuOpen ? 'navbar--open' : ''}`}>
        <button 
          alt="Open Menu"
          className="navbar__button navbar__button--open"
          onClick={ () => setmenuOpen(true) }
        />
        <div className={`navbar__links ${menuOpen ? 'navbar__links--open' : ''}`}>
          <Link className="navbar__link" to="/about" activeClassName="navbar__link--active">About</Link>
          <Link className="navbar__link" to="/blog" activeClassName="navbar__link--active" partiallyActive={true}>Blog</Link>
          <Link className="navbar__link" to="/contact" activeClassName="navbar__link--active">Contact</Link>
          <button 
            alt="Close Menu"
            className="navbar__button navbar__button--close"
            onClick={ () => setmenuOpen(false) }
          />
        </div>
      </nav>
    </header>
  )
}

export default Header
