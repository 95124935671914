import React from "react"
import { graphql, StaticQuery, Link } from 'gatsby'

import './footer.scss'

const Footer = ({ data }) => {
  const { edges: fonts } = data.allMarkdownRemark;
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-section footer-section--links">
        <div className="footer-column">
          <h5 className="footer-column__header">Fonts</h5>
          <ul className="footer-column__list">
          { fonts &&
            fonts.map(({ node: font }, i) => (
              <li className="footer-column__list-item" key={ i }>
                <a href={`/${font.frontmatter.path}`}>{ font.frontmatter.title }</a>
              </li>
            ))
          }
          </ul>
        </div>
        <div className="footer-column">
          <h5 className="footer-column__header">Links</h5>
          <ul className="footer-column__list">
            <li className="footer-column__list-item">
              <Link to="/about">About</Link>
            </li>
            <li className="footer-column__list-item">
              <Link to="/blog">Blog</Link>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h5 className="footer-column__header">Get in touch</h5>
          <ul className="footer-column__list">
            <li className="footer-column__list-item">
              <Link to="/contact">Contact</Link>
            </li>
            <li className="footer-column__list-item">
              <a href='https://twitter.com/HazleyStuart'>Twitter</a>
            </li>
            <li className="footer-column__list-item">
              <a href='https://www.instagram.com/stuarthazleyfoundry/'>Instagram</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-section footer-section--info">
        <p>Copyright © { currentYear } Stuart Hazley.</p>
        <p>Website built by <a href="https://ryanbrowne.io">Ryan</a>.</p>
      </div>
    </footer>
  );
}

export default () => (
  <StaticQuery 
    query={graphql`
      query FontFooterQuery {
        allMarkdownRemark(
          sort: {order: DESC, fields: frontmatter___date},
          filter: {frontmatter: {content_type: {eq: "font"}}},
          limit: 6
        ) {
          edges {
            node {
              id
              frontmatter {
                path
                title
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <Footer data={data} count={count} />}
  />
)
