import React from "react"
import MailchimpForm from "react-mailchimp-form"

import "./newsletter.scss"

const url =
  "https://work.us19.list-manage.com/subscribe/post?u=80ff579cea32261392c1630db&amp;id=e21c9c6fa1"
const messages = {
  sending: "Sending...",
  success: "Thank you for subscribing!",
  error: "An unexpected internal error has occurred.",
  empty: "Please provide an email address.",
  duplicate: "This email address has already been used",
  button: "Subscribe",
}

const Newsletter = ({ handleClick }) => {
  return (
    <div className="newsletter">
      <div className="newsletter__form">
        <h5 className="newsletter__text">
          Sign up to my newsletter to keep up to date with typeface releases and
          blog updates.
        </h5>
        <MailchimpForm
          action={url}
          fields={[
            {
              name: "EMAIL",
              placeholder: "Email Address",
              type: "email",
              required: true,
            },
          ]}
          messages={messages}
        />
        <button className="newsletter__close" onClick={handleClick} />
      </div>
    </div>
  )
}

export default Newsletter
