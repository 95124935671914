/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header/header"
import Footer from "./footer/footer"
import Newsletter from "./newsletter/newsletter"

import "../styles/main.scss"

const Layout = ({ children }) => {
  const [showNewsletter, setShowNewsletter] = useState(false)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (localStorage.getItem("newsletterShown") !== "shown") {
        setShowNewsletter(true)
      }
    }, 2000)
    return () => clearTimeout(timer)
  }, [])

  const handleNewsletterClick = () => {
    localStorage.setItem("newsletterShown", "shown")
    setShowNewsletter(false)
  }

  return (
    <div className="site-content">
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Footer />
      {showNewsletter && <Newsletter handleClick={handleNewsletterClick} />}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
